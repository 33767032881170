<template>
  <tr class="border-left" :class="'border-' + activeClass">
    <td class="align-middle text-center">
      <i :class="statusClassIcon"></i>
    </td>
    <td class="align-middle text-right">
      {{ payment.created_at | moment("DD MMMM YYYY") }}
      <small class="d-block">{{
        payment.created_at | moment("h:mm:ss")
      }}</small>
    </td>
    <td class="align-middle text-right">
      {{ payment.amount + " " + payment.currency }}
      <small class="d-block" :class="'text-' + activeClass">
        <i class="fa fa-arrow-alt-circle-down"></i>
        {{ payment.status }}
      </small>
    </td>
    <td class="align-middle text-left">
      {{ payment.fee_amount + " " + payment.currency }}
    </td>
    <td class="align-middle text-center">
      <img
        :title="operatorLabel"
        class="img-fluid rounded img-thumbnail"
        style="width:45px"
        :src="operatorLogo"
        :alt="operatorLabel"
      />
    </td>
    <td class="align-middle">
      {{ payment.payment_id }}
    </td>
    <td class="align-middle text-center">
      <router-link
        :to="{ name: 'payment.detail', params: { id: payment.id } }"
        class="p-1 shadow-sm text-capitalize md-trigger text-secondary"
      >
        <i class="fa fa-eye mr-0"></i> Détails
      </router-link>
    </td>
  </tr>
</template>

<script>
import { paymentMixin } from "../../mixins/paymentMixin";

export default {
  name: "PaymentTableLine",
  mixins: [paymentMixin]
};
</script>

<style scoped></style>

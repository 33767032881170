<template>
  <div>
    <div class="form-group text-center">
      <router-link :to="{ name: 'payment' }" class="btn btn-secondary">
        <i class="fa fa-arrow-left"></i>
        {{ $("common.back_to_applications") }}
      </router-link>
    </div>

    <div class="card d-none d-sm-block">
      <div class="card-header">
        <h5>
          {{ $("payment.payment_history") }}
        </h5>
        <div class="card-header-right col-lg-4">
          <BaseInputSearch
            v-model="paymentField.keyword"
            @cancel="cancelSearch()"
            @search="getPaymentList(1)"
          />
        </div>
      </div>
      <div v-if="payments.length > 0 || loading" class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="text-center" style="width: 30px">
                {{ $("common.status") }}
              </th>
              <th class="text-right" style="width: 40px">
                Date
              </th>
              <th class="text-right" style="width: 30px">
                {{ $("common.amount") }}
              </th>
              <th class="text-left" style="width: 30px">
                {{ $("common.fees") }}
              </th>
              <th class="text-center" style="width: 30px">
                {{ $("common.operator") }}
              </th>
              <th style="width: 60px">{{ $("common.reference") }}</th>
              <th class="text-center" style="width: 30px">
                {{ $("common.actions") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="loading">
              <BaseTableLineLoader v-for="n in 9" :key="n" />
            </template>
            <payment-table-line
              v-for="payment in payments"
              v-else
              :key="payment.id"
              :payment="payment"
            />
          </tbody>
        </table>
      </div>
      <BaseEmptyList
        v-else
        :keyword="paymentField.keyword"
        :model="listModel"
        :search="paymentField.search"
      />
    </div>
    <div class="d-block d-sm-none mb-2">
      <template v-if="loading">
        <BaseLoader class="mt-4 mb-4" text-color="text-info" />
      </template>
      <payment-mobile-row
        v-for="payment in payments"
        v-else
        :key="payment.id"
        :payment="payment"
      />
    </div>

    <BasePagination
      :current_page="current_page"
      :last_page="last_page"
      @page="getPaymentList"
    />
    <div v-if="isDetailsOpen" class="bg-secondary">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PaymentMobileRow from "../../components/payment/PaymentMobileRow";
import PaymentTableLine from "../../components/payment/PaymentTableLine";
import { emptyListModel } from "../../helpers/constants";

export default {
  name: "PaymentList",
  components: { PaymentTableLine, PaymentMobileRow },
  props: {
    appId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      paymentField: {
        applications_id: null,
        paginate: 10,
        search: false,
        keyword: null,
      },
    };
  },

  computed: {
    ...mapState("payment", ["metaPayment"]),
    ...mapGetters("payment", ["payments", "current_page", "last_page"]),
    isDetailsOpen() {
      return this.$route.name === "payment.detail";
    },
    listModel() {
      return emptyListModel.payment;
    },
  },

  created() {
    this.paymentField.applications_id = this.appId;
    this.getPaymentList(1);
  },

  methods: {
    getPaymentList(page, cancel = false) {
      this.loading = true;
      if (this.paymentField.keyword) this.paymentField.search = true;
      else {
        this.paymentField.keyword = null;
        this.paymentField.search = false;
      }
      this.$store
        .dispatch("payment/getPaymentList", {
          page: page,
          cancel: cancel,
          field: this.paymentField,
        })
        .then(() => {
          this.loading = false;
        });
    },
    cancelSearch() {
      this.paymentField.keyword = null;
      if (this.paymentField.search) {
        this.paymentField.search = false;
        this.getPaymentList(1, true);
      }
    },
  },
};
</script>

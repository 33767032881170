<template>
  <div class="row">
    <router-link
      :to="{ name: 'payment.detail', params: { id: payment.id } }"
      class="col-12 text-dark pb-1 pt-1 shadow-sm border-bottom bg-white"
    >
      <div class="d-flex align-items-center p-1">
        <div class="col-2 pl-0">
          <img
            :title="operatorLabel"
            class="img-fluid rounded img-thumbnail"
            style="width:40px; height: 20px"
            :src="operatorLogo"
            :alt="operatorLabel"
          />
        </div>
        <div class="col-7 pl-0 pr-0">
          <span class="f-12 text-truncate"
            >Paiement de {{ payment.amount + " " + payment.currency }}</span
          >
          <small class="d-block text-truncate">
            {{ payment.fee_amount + " " + payment.currency }} - Ref:
            {{ payment.payment_id }}</small
          >
        </div>
        <div class="col-auto text-center">
          <span class="f-10 text-truncate d-block">{{
            payment.created_at | moment("DD/MM/YYYY")
          }}</span>
          <small class="d-block" :class="'text-' + activeClass">
            <i class="fas" :class="statusClassIcon"></i>
            {{ payment.status }}
          </small>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { paymentMixin } from "../../mixins/paymentMixin";

export default {
  name: "PaymentMobileRow",
  mixins: [paymentMixin]
};
</script>

<style scoped></style>
